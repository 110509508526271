import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { apiResponseFactory } from 'src/app/models/response/apiResponseFactory';
import { AccountService } from 'src/app/shared/services/account.service';
import { BaseApiService } from 'src/app/shared/services/base-api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {
  baseUrl: string = environment.apiUrl;

  constructor(private httpClient: HttpClient, private api: BaseApiService, private accountService: AccountService) { }

  getTipoviArtikala() {
    return this.httpClient.get<apiResponseFactory>(this.baseUrl + 'configurations/tipove-artikala');
  }

  getPdvTarife() {
    return this.httpClient.get<apiResponseFactory>(this.baseUrl + 'configurations/pdv-tarife');
  }

  getNaciniPlacanja() {
    return this.httpClient.get<apiResponseFactory>(this.baseUrl + 'configurations/nacine-placanja')
  }


  getMemorandum(): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'get',
      `${environment.apiUrl}configurations/firma-memorandum`,
      {
        // autoBr:autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getTringExtension(): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'get',
      `${environment.apiUrl}configurations/tring-extension`,
      {
        // autoBr:autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
        // observe: 'response',
      }
    );
  }

  getNSCExtension(): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'get',
      `${environment.apiUrl}configurations/nsc-extension`,
      {
        // autoBr:autoBr,
      },
      {},
      {
        headers,
        responseType: 'blob',
      }
    );
  }

  getImportTemplate(type): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept-Language': 'hr-HR',
      firmaid: this.accountService.context?.organizationId?.toString() || '',
      godina: this.accountService.context?.year?.toString() || '',
      Authorization: 'Bearer ' + this.accountService.accessToken.token,
    });
    return this.api.newRequest(
      'post',
      `${environment.apiUrl}configurations/import-template`,
      {
        type: type,
      },
      {},
      {
        headers,
        responseType: 'blob',
        observe: 'response'
      }
    );
  }

  getGuid() {
    return this.api.get('configurations/get-guid').pipe(catchError(this.handleError<[]>(`configurations/get-guid`)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      const errorMsg = `${operation} failed: ${error.message}`;
      return throwError(error);
    };
  }

}
